<template>
  <div class="detail-item">
    <div class="title-wrap">
      <div class="index">明细{{ index }}</div>
      <van-tag type="primary">{{ detail.feeType }}</van-tag>
    </div>
    <div class="content-wrap">
      <div class="main-two">
        <div class="detail-cell">
          <div class="title">费用金额</div>
          <div class="value">￥{{ detail.amount }}</div>
        </div>
        <div class="detail-cell">
          <div class="title">所属客户/部门</div>
          <div class="value">{{ detail.customerName || detail.departmentName }}</div>
        </div>
      </div>

      <van-divider />

      <div class="bottom-wrap">
        <div class="bottom-cell">
          <span class="title">起始日期：</span>
          <span class="value">{{ detail.makeDate }} - {{ detail.makeDateEnd }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Divider, Tag } from 'vant';

Vue.use(Divider).use(Tag);
export default {
  name: 'DetailItem',
  props: {
    detail: {
      type: Object,
      require: true,
    },
    index: {
      type: Number,
      require: true,
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-item{
  margin-bottom: 20px;
  .index{
    color: $gray;
  }
  >.title-wrap{
    display: flex;
    margin-left: 6px;
    >.title{
      font-size: 14px;
      font-weight: 600;
      margin-right: 10px;
    }
    >.van-tag{
      background-color: $main;
      margin: 0 10px;
      padding: 0 10px;
      transform: skew(-20deg);
      border-radius: 2px;
    }
  }
  .content-wrap{
    background-color: #f7f8fcc9;
    border-radius: 6px;
    padding: 4px 10px;
    margin: 10px 0;
    @mixin main{
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
      .detail-cell{
        flex: 1;
        display: flex;
        flex-direction: column;
        &:nth-child(1){
          text-align: left;
        }
        .title{
          font-size: 12px;
          color: $gray;
        }
        .value{
          margin: 4px 0;
          font-size: 14px;
          font-weight: 600;
          color: $font;
        }
      }
    }
    .main-two{
      @include main;
      .detail-cell{
        &:nth-child(2){
          text-align: right;
        }
      }
    }
    .van-divider{
      color: #D8DBE4;
      border-color: #D8DBE4;
    }
  }
  .bottom-wrap{
    display: flex;
    justify-content: space-between;
    .bottom-cell{
      font-size: 12px;
      >.title{
        color: $gray;
      }
    }
  }
}
</style>